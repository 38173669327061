import { useEffect, useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { Error, MoreVertical, Writing } from "@remhealth/icons";
import {
  AdministrativeFlags,
  Appointment,
  Approximate,
  Attendee,
  DocumentationStatus,
  Encounter,
  EncounterDocumentationType,
  Group,
  Note,
  NoteExpandables,
  OffsetDateTime,
  ParticipantRole,
  Patient,
  Practitioner,
  Reference,
  ZonedDateTime,
  isApolloResponseError,
  securityRoleIds
} from "@remhealth/apollo";
import {
  Alert,
  AnchorButton,
  Button,
  ButtonGroup,
  IconButton,
  Intent,
  Menu,
  MenuItem,
  Popover,
  Tooltip,
  useAbort,
  useSubscription
} from "@remhealth/ui";
import {
  useApollo,
  usePracticePreferences,
  useProductFlag,
  useReleaseCheck,
  useTracking,
  useUserPermissions,
  useUserSession
} from "@remhealth/host";
import {
  ActivePullContext,
  useEhr,
  useErrorHandler,
  useErrorReporter,
  useLabeling,
  useStore,
  useStoreItem
} from "@remhealth/core";

import { StartNoteEncounter, useNoteDialog, usePatientAccessor } from "~/contexts";
import { Text } from "~/text";
import { ReadOnlyNoteDialog } from "~/notes/view/readOnlyNoteDialog";
import { clinicalRoutes } from "~/routes";
import { isExternalAppointment, isGroupAppointment, useAppointmentStatus } from "./utils";
import { CancelAppointment, CancelAppointmentControlType } from "./cancelAppointment";
import { ActionWrapper, ConfigureAlertMessage, ConfigureAlertTitle } from "./appointmentActions.styles";

export interface AppointmentActionsProps {
  appointment: Appointment;
  associatedEncounter?: Encounter;
  location: string;
  onAction?: () => void;
}

export const AppointmentActions = (props: AppointmentActionsProps) => {
  const permissions = useUserPermissions();
  const navigate = useNavigate();

  const [previewNote, setPreviewNote] = useState<Note>();
  const [showConfigureAlert, setShowConfigureAlert] = useState<string>();

  const isPreferencesMaster = permissions.hasFlag("AdministrativeFlags", AdministrativeFlags.PreferencesMaster);

  return (
    <>
      <AppointmentActionContent {...props} onConfigureGroupNoteType={handleConfigure} onPreview={handlePreview} />
      <Alert
        cancelButtonText={isPreferencesMaster ? Text.Cancel : ""}
        confirmButtonText={isPreferencesMaster ? "Configure" : Text.Okay}
        intent="primary"
        isOpen={!!showConfigureAlert}
        onCancel={handleCancelConfigure}
        onConfirm={handleConfirmConfigure}
      >
        <ConfigureAlertTitle>{Text.NoteTypeNotConfigured}</ConfigureAlertTitle>
        <ConfigureAlertMessage>{isPreferencesMaster
          ? Text.NoNoteTypeConfiguredWarningForAdmin
          : Text.NoNoteTypeConfiguredWarningForNonAdmin}
        </ConfigureAlertMessage>
      </Alert>
      {previewNote && <ReadOnlyNoteDialog isOpen note={previewNote} onClose={handlePreviewClose} />}
    </>
  );

  function handlePreview(note: Note) {
    setPreviewNote(note);
  }

  function handlePreviewClose() {
    setPreviewNote(undefined);
  }

  function handleConfigure(groupId: string) {
    setShowConfigureAlert(groupId);
  }

  function handleCancelConfigure() {
    setShowConfigureAlert(undefined);
  }

  function handleConfirmConfigure() {
    setShowConfigureAlert(undefined);

    if (isPreferencesMaster && showConfigureAlert) {
      const path = generatePath(clinicalRoutes.groups);
      navigate(path);
    } else {
      setShowConfigureAlert(undefined);
    }
  }
};

interface AppointmentActionContentProps extends AppointmentActionsProps {
  onPreview: (note: Note) => void;
  onConfigureGroupNoteType: (selectedGroupId: string) => void;
}

function AppointmentActionContent(props: AppointmentActionContentProps) {
  const { associatedEncounter, location, onAction, onPreview, onConfigureGroupNoteType } = props;

  const accessor = usePatientAccessor();
  const apollo = useApollo();
  const preference = usePracticePreferences();
  const abort = useAbort();
  const noteDialog = useNoteDialog();
  const labels = useLabeling();
  const reportError = useErrorReporter();
  const tracking = useTracking();
  const user = useUserSession();
  const store = useStore();
  const ehr = useEhr();
  const handleError = useErrorHandler();
  const { isCancelledStatus, isNoShowStatus } = useAppointmentStatus();

  const appointment = useStoreItem(store.appointments, props.appointment);

  const hasAmbientListeningRole = user.person.profile.roles.some(r => r.id === securityRoleIds.AmbientListening);

  const allowCancelAppt = useProductFlag("AllowCancelAppt");
  const serviceLocationKind = useProductFlag("ServiceLocationKind");
  const allowStartFromCancelledAppt = useProductFlag("CanStartNoteFromCancelledAppt");
  const requireApptEpisodeToStartNote = useProductFlag("RequireApptEpisodeToStartNote");
  const showScribeSessionButton = useReleaseCheck("WebScribe") && hasAmbientListeningRole;
  const isMultiServiceNoteReleased = useReleaseCheck("MultiServiceNote");

  const [activePull, setActivePull] = useSubscription(ActivePullContext);

  const [hasGroupNoteDefinition, setHasGroupNoteDefinition] = useState(false);
  const [alertMsg, setAlertMsg] = useState<string>();

  const isCancelled = isCancelledStatus(appointment.status);
  const isNoShow = isNoShowStatus(appointment.status);

  const isExternal = isExternalAppointment(appointment, associatedEncounter);
  const isRestrictedPatient = checkIfRestrictedPatient();

  useEffect(() => {
    loadGroupNoteDefinition();
  }, [appointment.id, associatedEncounter?.id]);

  if (alertMsg) {
    return (
      <Alert isOpen icon={<Error />} intent="danger" onConfirm={() => setAlertMsg(undefined)}>
        <p>{alertMsg}</p>
      </Alert>
    );
  }

  if (!allowStartFromCancelledAppt && !associatedEncounter && (isCancelled || isNoShow)) {
    return <></>;
  }

  if (isExternal) {
    return <></>;
  }

  const canStartOrPreview = isOwnAppointment(appointment) || associatedEncounter?.documentationStatus === DocumentationStatus.Complete;

  if (isGroupAppointment(appointment)) {
    if (!canStartOrPreview || !isGroupAppointment(appointment, true)) {
      return <></>;
    }

    return (
      <ActionWrapper>
        <Button
          minimal
          disabled={!appointment.isGroup}
          icon={<Writing />}
          intent={Intent.PRIMARY}
          label={renderButtonText()}
          onClick={handleStartGroupNote}
        />
      </ActionWrapper>
    );
  }

  if (!isSinglePatientAppointment(appointment)) {
    return <></>;
  }

  if (!canStartOrPreview) {
    return renderCancelAppointment("button", true);
  }

  const cancelAppointmentOption = renderCancelAppointment("menuItem");
  const scribeSessionOption = showScribeSessionButton && (
    <MenuItem
      text={Text.StartScribeSession(labels)}
      onClick={() => handleStartNote(EncounterDocumentationType.ScribeSession)}
    />
  );

  const multiServiceNoteOption = isMultiServiceNoteReleased && (
    <MenuItem
      text={Text.StartMultiServiceNote}
      onClick={() => handleStartNote(EncounterDocumentationType.MultiServiceNote)}
    />
  );

  return (
    <ButtonGroup minimal>
      <Tooltip content={Text.RestrictedPatient(labels)} disabled={!isRestrictedPatient}>
        <Tooltip content={Text.AppointmentWithEnrollment(labels)} disabled={!disableIfEnrollmentNotPresent()}>
          <AnchorButton
            disabled={isRestrictedPatient || disabledNoteAction() || disableIfEnrollmentNotPresent()}
            intent={Intent.PRIMARY}
            label={renderButtonText()}
            loading={activePull.appointmentIds.has(appointment.id)}
            onClick={() => handleStartNote("Note")}
          />
        </Tooltip>
      </Tooltip>
      {(cancelAppointmentOption || scribeSessionOption || multiServiceNoteOption) && (
        <Popover
          content={(
            <Menu>
              {cancelAppointmentOption}
              {scribeSessionOption}
              {multiServiceNoteOption}
            </Menu>
          )}
          placement="bottom"
        >
          <IconButton aria-label="More Options" icon={<MoreVertical />} intent="primary" />
        </Popover>
      )}
    </ButtonGroup>
  );

  function renderCancelAppointment(controlType: CancelAppointmentControlType, minimal:boolean = false) {
    if (!allowCancelAppt || !preference.allowAppointmentCancellation) {
      return null;
    }

    if (associatedEncounter?.documentationStatus !== DocumentationStatus.Complete) {
      return <CancelAppointment appointment={appointment} controlType={controlType} minimal={minimal} />;
    }

    return null;
  }

  function renderButtonText() {
    if (associatedEncounter && !isOwner(associatedEncounter)) {
      return Text.PreviewNote;
    }

    switch (associatedEncounter?.documentationStatus) {
      case DocumentationStatus.Complete:
        return Text.PreviewNote;
      case DocumentationStatus.InProgress:
        return Text.EditNote;
      case DocumentationStatus.NotStarted:
      default:
        return Text.StartNote;
    }
  }

  function disabledNoteAction() {
    if (associatedEncounter && !isOwner(associatedEncounter)) {
      return false;
    }

    switch (associatedEncounter?.documentationStatus) {
      case DocumentationStatus.Complete:
      case DocumentationStatus.InProgress:
        return false;
      case DocumentationStatus.NotStarted:
      default:
        if (isCancelled) {
          return !allowStartFromCancelledAppt;
        }
        return false;
    }
  }

  function disableIfEnrollmentNotPresent() {
    if (requireApptEpisodeToStartNote && !isGroupAppointment(appointment)) {
      return !appointment.episodeOfCare;
    }
    return false;
  }

  function checkIfRestrictedPatient() {
    const patient = appointment.attendees.find(a => a.subject.resourceType === "Patient");
    if (isGroupAppointment(appointment) || !patient) {
      return false;
    }

    if (!patient.subject.resource) {
      return true;
    }
    return false;
  }

  function isOwner(encounter: Encounter) {
    return encounter.participants.some(p => p.role === ParticipantRole.PrimaryPerformer && p.individual.id === user.person.id);
  }

  function isOwnAppointment(appointment: Appointment) {
    return appointment.attendees.some(a => a.subject.resourceType === "Practitioner" && a.subject.id === user.person.id);
  }

  async function loadGroupNoteDefinition() {
    const group = appointment.attendees.find(a => a.subject.resourceType === "Group")?.subject.resource as Group | undefined;
    if (!group) {
      setHasGroupNoteDefinition(false);
      return;
    }

    abort.reset();
    setHasGroupNoteDefinition(!!group.definition);
  }

  async function getNote(encounter: Encounter): Promise<Note | undefined> {
    const response = await apollo.notes.query({
      filters: [{
        subject: { in: [encounter.subject.id] },
        encounter: { in: [encounter.id] },
      }],
      feedOptions: {
        partition: encounter.subject.id,
        maxItemCount: 1,
      },
      responseOptions: {
        expansions: [
          NoteExpandables.Encounter,
          NoteExpandables.Definition,
          NoteExpandables.Subject,
        ],
      },
      abort: abort.signal,
    });
    return response.results[0];
  }

  async function pullAppointment(appointmentId: string) {
    setActivePull(state => ({ ...state, appointmentIds: new Set([...state.appointmentIds, appointmentId]) }));
    try {
      return await ehr.pull("Appointment", appointmentId, abort.signal);
    } finally {
      setActivePull(state => ({ ...state, appointmentIds: new Set([...state.appointmentIds].filter(id => id !== appointmentId)) }));
    }
  }

  // Last-minute sync up to ensure its still not external
  async function checkCanStartNote(): Promise<boolean> {
    if (associatedEncounter) {
      return true;
    }

    // Confirm appointment still exists
    try {
      await apollo.appointments.fetchById(appointment.partition, appointment.id);
    } catch (error) {
      if (isApolloResponseError(error) && (error.response.status === 404 || error.response.status === 410)) {
        setAlertMsg("This appointment no longer exists.");
        return false;
      }

      handleError(error);
      return false;
    }

    try {
      const result = await pullAppointment(appointment.id);
      if (result.outcome === "Deleted" || result.outcome === "NotFound") {
        setAlertMsg("This appointment no longer exists.");
        return false;
      }
    } catch (error) {
      reportError(error);
    }

    try {
      const updatedAppt = await apollo.appointments.fetchById(appointment.partition, appointment.id);

      if (isExternalAppointment(updatedAppt)) {
        setAlertMsg(`There is already a note in ${labels.Product} for this appointment.`);
        return false;
      }
    } catch (error) {
      if (isApolloResponseError(error) && (error.response.status === 404 || error.response.status === 410)) {
        setAlertMsg("This appointment no longer exists.");
      }
      return false;
    }

    return true;
  }

  async function handleStartNote(documentationType: EncounterDocumentationType) {
    try {
      const patientAttendee = appointment.attendees.find(a => a.subject.resourceType === "Patient");
      if (patientAttendee && await accessor.assert(patientAttendee.subject as Reference<Patient>)) {
        if (associatedEncounter) {
          const note = await getNote(associatedEncounter);

          if (note) {
            if (associatedEncounter.documentationStatus === DocumentationStatus.InProgress && isOwner(associatedEncounter)) {
              tracking.track("Note - Open", { location, state: "Maximized" });
              noteDialog.openNote(note);
            } else {
              tracking.track("Note - Preview", { location, state: note.status });
              onPreview(note);
            }
          } else {
            tracking.track("Note - Start Note", { location });
            noteDialog.startNote(associatedEncounter, { initializeLocationFromPastVisit: false, documentationType });
          }
        } else if (await checkCanStartNote()) {
          const serviceLocationRef = serviceLocationKind ? appointment.serviceLocation : appointment.location;
          const serviceLocation = serviceLocationRef ? await store.locations.expand(serviceLocationRef, { abort: abort.signal }) : null;
          const startNoteEncounter: StartNoteEncounter = {
            resourceType: "Encounter",
            subject: {
              id: patientAttendee.subject.id,
              display: patientAttendee.subject.display,
              resourceType: "Patient",
              resource: patientAttendee.subject.resource as Patient,
            },
            period: {
              start: Approximate.fromFull(OffsetDateTime.fromDate(ZonedDateTime.toDate(appointment.start))),
              end: Approximate.fromFull(OffsetDateTime.fromDate(ZonedDateTime.toDate(appointment.end))),
            },
            episodeOfCare: appointment.episodeOfCare,
            program: appointment.program,
            serviceType: appointment.serviceType,
            location: serviceLocationKind
              ? appointment.serviceLocation ? { location: appointment.serviceLocation, role: serviceLocation?.defaultRole } : undefined
              : appointment.location ? { location: appointment.location, kind: appointment.serviceLocation, role: serviceLocation?.defaultRole } : undefined,
            appointment: {
              resourceType: "Appointment",
              partition: appointment.partition,
              id: appointment.id,
              display: appointment.display,
            },
            participants: appointment.attendees.filter(a => a.subject.resourceType === "Practitioner")
              .map(a => ({ individual: a.subject as Reference<Practitioner>, role: getDefaultPerformer(a) })),
          };
          tracking.track("Note - Start Note", { location });
          noteDialog.startNote(startNoteEncounter, { initializeLocationFromPastVisit: false, documentationType });
        }
      }
      onAction?.();
    } catch (error) {
      handleError(error);
    }
  }

  async function handleStartGroupNote() {
    try {
      const groupId = appointment.attendees.find(a => a.subject.resourceType === "Group")?.subject.id;
      const group = await store.groups.fetch(groupId!, { abort: abort.signal });
      if (!hasGroupNoteDefinition && group) {
        onConfigureGroupNoteType(group.id);
        return;
      }
      if (associatedEncounter) {
        const note = await getNote(associatedEncounter);
        if (note && associatedEncounter.documentationStatus === DocumentationStatus.Complete) {
          tracking.track("Note - Preview", { location, state: note.status });
          if (note.partOf) {
            onPreview(await store.notes.expand(note.partOf, { abort: abort.signal }));
          } else {
            onPreview(note);
          }
        } else if (note && associatedEncounter.documentationStatus === DocumentationStatus.InProgress) {
          tracking.track("Note - Open", { location, state: "Maximized" });
          noteDialog.openNote(note);
        }
      } else if (await checkCanStartNote()) {
        const serviceLocationRef = serviceLocationKind ? appointment.serviceLocation : appointment.location;
        const serviceLocation = serviceLocationRef ? await store.locations.expand(serviceLocationRef, { abort: abort.signal }) : null;
        const participants = appointment.attendees.filter(a => a.subject.resourceType === "Practitioner");
        const startNoteEncounter: StartNoteEncounter = {
          resourceType: "Encounter",
          subject: {
            id: group.id,
            display: group.display,
            resourceType: "Group",
            resource: group,
          },
          attendees: appointment.attendees,
          program: appointment.program,
          period: {
            start: Approximate.fromFull(OffsetDateTime.fromDate(ZonedDateTime.toDate(appointment.start))),
            end: Approximate.fromFull(OffsetDateTime.fromDate(ZonedDateTime.toDate(appointment.end))),
          },
          serviceType: appointment.serviceType,
          location: serviceLocationKind
            ? appointment.serviceLocation ? { location: appointment.serviceLocation, role: serviceLocation?.defaultRole } : undefined
            : appointment.location ? { location: appointment.location, kind: appointment.serviceLocation, role: serviceLocation?.defaultRole } : undefined,
          appointment: {
            resourceType: "Appointment",
            partition: appointment.partition,
            id: appointment.id,
            display: appointment.display,
          },
          participants: participants.map(a => ({ individual: a.subject as Reference<Practitioner>, role: a.role ?? getDefaultPerformer(a) })),
        };
        tracking.track("Group Note - Start Note", { location });
        noteDialog.startNote(startNoteEncounter, { initializeLocationFromPastVisit: false, documentationType: EncounterDocumentationType.Note });
      }
      onAction?.();
    } catch (error) {
      handleError(error);
    }
  }

  function getDefaultPerformer(attendee: Attendee) {
    if (attendee.subject.id === user.person.id) {
      return ParticipantRole.PrimaryPerformer;
    }

    return ParticipantRole.Participation;
  }
}

function isSinglePatientAppointment(appointment: Appointment) {
  return appointment.attendees.filter(a => a.subject.resourceType === "Patient").length === 1;
}
