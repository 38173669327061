import { useEffect, useRef, useState } from "react";
import { Encounter, HealthcareService, HealthcareServiceFilterSet, LocalDate, LocationRole, Reference, ServiceCharacteristics } from "@remhealth/apollo";
import { isDateRangeEffective, resourceEquals, useApollo, useProductFlag } from "@remhealth/host";
import { Labeling, useEhr, useLabeling } from "@remhealth/core";
import {
  AsyncSuggest,
  DefaultMoreResultsAvailable,
  DefaultSuggestInitialContent,
  FormField,
  FormGroup,
  FormScope,
  ItemAsyncListRendererProps,
  Menu,
  MenuDivider,
  PagingResult,
  Tooltip,
  useCallbackRef,
  useUpdateEffect
} from "@remhealth/ui";

import { PagedResults } from "@remhealth/mastodon";
import { Text } from "~/text";
import { ServiceTypeDialog } from "~/serviceTypes/serviceTypeDialog";
import { EhrServiceTypeDialog } from "~/serviceTypes/ehrServiceTypeDialog";
import { ViewAllButton } from "./common.styles";

export interface ServiceTypeSelectProps {
  allowEhr?: boolean;
  clearable?: boolean;
  large?: boolean;
  disabled?: boolean;
  patientId: string | undefined;
  episodeOfCareId: string | undefined;
  programId: string | undefined;
  locationId: string | undefined;
  locationRole?: LocationRole;
  visitDate: LocalDate | undefined;
  recentEncounters?: Encounter[];
  selectedItem?: HealthcareService | null;
  name?: string;
  field?: FormField<HealthcareService | undefined>;
  relatesToAppointment?: boolean;
  showLabel?: boolean;
  limitToServiceTypeIds?: string[];
  excludedHealthcareServiceIds?: string[];
  missedVisitsOnly?: boolean;
  onChange?: (serviceType: HealthcareService | null) => void;
  renderOption?: (serviceType: HealthcareService) => string | JSX.Element;
}

export const ServiceTypeSelect = (props: ServiceTypeSelectProps) => {
  const {
    patientId,
    episodeOfCareId,
    programId,
    locationId,
    locationRole,
    visitDate,
    recentEncounters = [],
    selectedItem,
    clearable,
    large,
    name,
    field,
    limitToServiceTypeIds,
    excludedHealthcareServiceIds,
    relatesToAppointment = false,
    showLabel = true,
    missedVisitsOnly = false,
    onChange,
    renderOption,
  } = props;

  // Hooks
  const apollo = useApollo();
  const ehrService = useEhr();
  const labels = useLabeling();
  const serviceTypeSelect = useRef<AsyncSuggest>(null);
  const liveCall = useProductFlag("ServiceTypeLiveCall");
  const liveCallByPatient = useProductFlag("ServiceTypeByPatientCall");

  const allowEhr = liveCall && props.allowEhr && limitToServiceTypeIds === undefined;

  // State
  const [recentServiceTypes, setRecentServiceTypes] = useState<Reference<HealthcareService>[]>([]);
  const [showDialog, setShowDialog] = useState(false);

  const clearSelectionCallback = useCallbackRef(clearSelectionIfNeeded);

  useEffect(() => {
    if (!allowEhr) {
      setRecentServiceTypes(recentEncounters.flatMap(e => e.serviceType ?? []));
    }
  }, [allowEhr, recentEncounters.length]);

  const missingDependency = getMissingDependency();
  const disabled = props.disabled || !!missingDependency;

  useUpdateEffect(() => {
    clearSelectionCallback();
    resetSuggestions();
  }, [patientId, episodeOfCareId, programId, locationId, locationRole, visitDate, recentServiceTypes]);

  return (
    <FormScope label={labels.ServiceType}>
      {showLabel ? (
        <FormGroup
          field={field}
          label={labels.ServiceType}
          labelInfo={(
            <Tooltip content={missingDependency ?? ""} disabled={!missingDependency} targetTagName="span">
              <ViewAllButton
                minimal
                small
                disabled={disabled}
                intent="primary"
                label="View All"
                onClick={handleViewAll}
              />
            </Tooltip>
          )}
        >
          {renderSelect()}
        </FormGroup>
      ) : renderSelect()}
      {renderDialog()}
    </FormScope>
  );

  function renderSelect() {
    return (
      <Tooltip content={missingDependency ?? ""} disabled={!missingDependency}>
        {allowEhr ? (
          <AsyncSuggest<HealthcareService>
            ref={serviceTypeSelect}
            fill
            clearable={clearable}
            disabled={disabled}
            field={field}
            infoRenderer={i => i.aliases[0]}
            itemListRenderer={i => renderHealthcareServiceList(i, labels)}
            itemsEqual={resourceEquals}
            large={large}
            leftIcon="search"
            name={name}
            optionRenderer={i => renderOption ? renderOption(i) : i.display}
            queryable={ehrQueryable}
            selectedItem={selectedItem}
            onSelectedItemChange={onChange}
          />
        ) : (
          <AsyncSuggest<HealthcareService>
            ref={serviceTypeSelect}
            fetchOnBlankQuery
            fill
            clearable={clearable}
            disabled={disabled}
            field={field}
            infoRenderer={i => i.aliases[0]}
            itemListRenderer={i => renderHealthcareServiceList(i, labels)}
            itemsEqual={resourceEquals}
            large={large}
            leftIcon="search"
            name={name}
            optionRenderer={i => renderOption ? renderOption(i) : i.display}
            queryable={queryable}
            selectedItem={selectedItem}
            onSelectedItemChange={onChange}
          />
        )}
      </Tooltip>
    );
  }

  function getMissingDependency(): string | undefined {
    if (allowEhr) {
      if (!programId) {
        return `${Text.Program} required to search for ${labels.serviceTypes}.`;
      }

      if (!visitDate) {
        return `${Text.SessionDate(labels)} required to search for ${labels.serviceTypes}.`;
      }

      if (liveCallByPatient) {
        if (!patientId) {
          return `${labels.Patient} required to search for ${labels.serviceTypes}.`;
        }

        if (!episodeOfCareId) {
          return `${labels.Enrollment} required to search for ${labels.serviceTypes}.`;
        }
      } else if (!locationId) {
        return `${labels.Location} required to search for ${labels.serviceTypes}.`;
      }
    }

    return undefined;
  }

  function clearSelectionIfNeeded() {
    if (field && clearable) {
      field.setError(undefined);
      field.setTouched(false);

      if (field.value) {
        // Don't auto-clear if we defaulted the value from an appointment
        if (allowEhr && field.initialValue !== undefined && field.value?.id === field.initialValue.id) {
          field.onChange(undefined);
        } else if (locationRole && field.value.allowedLocations.length > 0 && !field.value.allowedLocations.includes(locationRole)) {
          field.onChange(undefined);
        } else if (!isDateRangeEffective(field.value.effective, LocalDate.toDate(visitDate))) {
          field.onChange(undefined);
        }
      }
    }
  }

  function resetSuggestions() {
    serviceTypeSelect.current?.resetQuery();
  }

  function renderDialog() {
    if (!showDialog || disabled) {
      return null;
    }

    if (allowEhr && !missedVisitsOnly) {
      return (
        <EhrServiceTypeDialog
          isOpen
          onClose={handleCloseDialog}
          onLoad={ehrLoad}
          onSelect={handleSelect}
        />
      );
    }

    return (
      <ServiceTypeDialog
        isOpen
        excludedHealthcareServiceIds={excludedHealthcareServiceIds}
        limitToServiceTypeIds={limitToServiceTypeIds}
        locationRole={locationRole}
        missedVisitsOnly={missedVisitsOnly}
        visitDate={visitDate}
        onClose={handleCloseDialog}
        onSelect={handleSelect}
      />
    );
  }

  async function ehrQueryable(query: string, abort: AbortSignal): Promise<PagingResult<HealthcareService>> {
    if (!query) {
      return { hasMore: false, items: [] };
    }

    const response = await ehrLoad(query, 10, undefined, abort);
    return { hasMore: !!response.continuationToken, items: response.results };
  }

  async function queryable(query: string, abort: AbortSignal): Promise<PagingResult<HealthcareService>> {
    if (!query) {
      const services = await apollo.healthcareServices.expand(recentServiceTypes);
      const filtered = services
        .filter(s => limitToServiceTypeIds ? limitToServiceTypeIds.includes(s.id) : true)
        // Exclude deleted and historical services since this is a new note being created and shouldn't refererence old values
        .filter(s => !s.meta?.isDeleted && !s.meta?.isHistorical)
        // Exclude services not applicable to the given locationRole
        .filter(s => !locationRole || s.allowedLocations.includes(locationRole))
        // Exclude expired services
        .filter(s => !visitDate || isDateRangeEffective(s.effective, LocalDate.toDate(visitDate)))
        .filter(s => missedVisitsOnly ? s.characteristics.includes(ServiceCharacteristics.MissedVisit) : true);

      return { items: filtered, hasMore: true };
    }

    if (limitToServiceTypeIds && limitToServiceTypeIds.length === 0) {
      return { items: [], hasMore: false };
    }

    let filterSet: HealthcareServiceFilterSet = {
      text: { startsWithAllWords: query },
      effective: {
        wraps: visitDate,
      },
      ids: limitToServiceTypeIds,
    };

    // Location filter
    if (locationRole) {
      filterSet = {
        ...filterSet,
        ...{
          allowedLocations: {
            matches: locationRole,
            presence: "MaybePresent",
          },
        },
      };
    }

    if (missedVisitsOnly) {
      filterSet = {
        ...filterSet,
        ...{
          characteristics: {
            in: [ServiceCharacteristics.MissedVisit],
            presence: "MustBePresent",
          },
        },
      };
    }

    const response = await apollo.healthcareServices.query({
      filters: [filterSet],
      orderBy: {
        field: "Display",
        direction: "Ascending",
      },
      feedOptions: {
        maxItemCount: 10,
      },
      abort,
    });
    return { items: response.results, hasMore: !!response.continuationToken };
  }

  async function ehrLoad(query: string, limit: number, continuationToken: string | undefined, abort: AbortSignal): Promise<PagedResults<HealthcareService>> {
    const feedOptions = { limit, continuationToken, excludedHealthcareServiceIds };

    const purpose = patientId ? relatesToAppointment ? "Appointment" : "Patient" : "Group";

    if (liveCallByPatient) {
      if (!episodeOfCareId || !patientId || !programId || !visitDate) {
        return { continuationToken: undefined, results: [] };
      }

      return await ehrService.searchServiceTypesByPatient(query, feedOptions, patientId, episodeOfCareId, programId, visitDate, purpose, false, abort);
    }

    if (!programId || !locationId || !visitDate) {
      return { continuationToken: undefined, results: [] };
    }

    return await ehrService.searchServiceTypesByProgram(query, feedOptions, programId, locationId, visitDate, purpose, abort);
  }

  function handleViewAll() {
    setShowDialog(true);
  }

  function handleCloseDialog() {
    setShowDialog(false);
  }

  function handleSelect(serviceType: HealthcareService) {
    if (field) {
      field.onChange(serviceType);
      field.onTouched();
    }

    handleCloseDialog();
    onChange?.(serviceType);
  }
};

function renderHealthcareServiceList(listProps: ItemAsyncListRendererProps<HealthcareService>, labels: Labeling) {
  if (listProps.items.length === 0 && listProps.filteredItems.length === 0 && !listProps.query) {
    return (
      <Menu {...listProps.menuProps} ulRef={listProps.itemsParentRef}>
        {DefaultSuggestInitialContent}
      </Menu>
    );
  }

  return (
    <Menu {...listProps.menuProps} ulRef={listProps.itemsParentRef}>
      {!listProps.query && listProps.items.length > 0 && (
        <MenuDivider title={Text.RecentServiceTypes(labels)} />
      )}
      {listProps.renderItems()}
      {listProps.query && listProps.items.length > 0 && listProps.hasMore && DefaultMoreResultsAvailable}
      {!listProps.query && DefaultSuggestInitialContent}
    </Menu>
  );
}
