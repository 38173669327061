import { usePersonalPreferences, usePracticePreferences, useUserSession } from "@remhealth/host";
import { useLabeling, useStore } from "@remhealth/core";
import { NoteSectionFormat, PatientNote } from "@remhealth/apollo";
import { useAbort } from "@remhealth/ui";
import { htmlToText } from "@remhealth/compose";
import { Text } from "~/text";
import { usePatientAccessor } from "~/contexts";
import { generateNoteSummaryMarkup } from "./noteSummaryPreview";
import { LoadNoteSummaryOptions } from "./noteSummary";

export interface SummarizeNoteOptions {
  isPrintView?: boolean;
}

export function useNoteSummary() {
  const store = useStore();
  const abort = useAbort();
  const session = useUserSession();
  const labels = useLabeling();
  const accessor = usePatientAccessor();
  const personalPreferences = usePersonalPreferences();
  const practicePreferences = usePracticePreferences();

  return {
    summarizeNote,
  };

  async function summarizeNote(note: PatientNote, summaryOptions?: SummarizeNoteOptions): Promise<void> {
    if (!await accessor.test(note.subject)) {
      return;
    }

    const options: LoadNoteSummaryOptions = {
      practice: session.practice,
      practicePreferences,
      personalPreferences,
      labels,
      allowImages: false,
      isPatientView: false,
      isPrintView: summaryOptions?.isPrintView ?? false,
    };

    const markup = await generateNoteSummaryMarkup(store, note, options, abort.signal);
    const summaryHtml = markup.summary;
    const summaryText = htmlToText(summaryHtml);

    const sectionMarkup = markup.sections.map(section => ({
      name: section.name,
      value: section.markup ?? undefined,
      plainText: section.markup ? htmlToText(section.markup) : undefined,
    }));

    if (note.partOf) {
      const sections = note.sections.filter(s => s.format !== NoteSectionFormat.ItineraryTopics);
      note.sections = [
        {
          name: Text.GroupTopics,
          format: NoteSectionFormat.ItineraryTopics,
          required: false,
          includeInPatientView: false,
          includeSectionHeader: false,
          filterGoals: false,
          formAnswers: [],
          requireComments: false,
          requiredGoalElementType: "Goal",
          requireGoalsWhenPresent: false,
          useEvidenceBasedPracticeElements: false,
        },
        ...sections,
      ];
    }

    note.sections = note.sections.map(section => {
      const text = section.format === "Text"
        ? section.text
        : sectionMarkup.find(s => s.name === section.name) ?? section.text;

      return {
        ...section,
        text: !text ? undefined : {
          plainText: text.plainText,
          value: text.value,
        },
        formAnswers: section.formAnswers.map(answer => ({
          ...answer,
          values: answer.values.map(value => {
            const elementMarkup = markup.elements.get(answer.linkId);
            if (!elementMarkup) {
              return value;
            }

            // Update text for Long Text questions linked to CNS
            return {
              ...value,
              valueText: {
                value: elementMarkup,
                plainText: htmlToText(elementMarkup),
              },
            };
          }),
        })),
      };
    });

    note.summary = {
      value: summaryHtml,
      plainText: summaryText,
    };

    note.summaryDocument = undefined;
  }
}
