import { useNotesView } from "@remhealth/core";
import { NoteViewer, NoteViewerProps } from "./noteViewer";

export type GroupNoteViewerProps = Omit<NoteViewerProps, "view">;

export const GroupNoteViewer = (props: GroupNoteViewerProps) => {
  const { filters = {} } = props;
  const view = useNotesView({ field: "Subject", direction: "Ascending" }, false, filters);

  return (
    <NoteViewer {...props} view={view} />
  );
};
