import { AdministrativeGender, DateRange } from "@remhealth/apollo";
import { parseDateQuery } from "./filters";

export interface ParsedPatientQuery {
  query?: string;
  birthDate?: DateRange;
  gender?: AdministrativeGender;
  patientId?: string;
  telecom?: string;
}

const genders = new Set(["m", "f", ...Object.values(AdministrativeGender).map(g => g.toLowerCase())]);
const patientIdRegex = / (\d+) /;
const phoneRegex = / (\+\d[ -]?)?((\(\d{3}\)[ -]?)|(\d{3}-)|(\d{3} ))(\d{1,3}[ -]?(\d{0,4})?) /;

export function parsePatientQuery(query: string): ParsedPatientQuery {
  const result: ParsedPatientQuery = {};

  const dateQuery = parseDateQuery(query);
  result.birthDate = dateQuery.date;

  const parts = (dateQuery.query ?? "").split(" ").filter(p => !!p.trim());

  const genderMatch = parts.findIndex(p => genders.has(p.toLowerCase()));
  if (genderMatch !== -1) {
    const matchedGender = parts[genderMatch];
    switch (matchedGender.toLowerCase()) {
      case "m":
      case "male":
        result.gender = "Male";
        break;
      case "f":
      case "female":
        result.gender = "Female";
        break;
      default:
        result.gender = matchedGender.charAt(0).toUpperCase() + matchedGender.slice(1).toLowerCase() as AdministrativeGender;
        break;
    }

    parts.splice(genderMatch, 1);
  }

  // Add padding around query so the regexes can search full words
  query = " " + parts.join(" ") + " ";

  const patientIdMatches = query.match(patientIdRegex)?.filter(m => !!m);
  if (patientIdMatches && patientIdMatches?.length > 0) {
    result.patientId = patientIdMatches[0].trim();
    query = query.replace(result.patientId, "");
  }

  const phoneMatches = query.match(phoneRegex)?.filter(m => !!m);
  if (phoneMatches && phoneMatches?.length > 0) {
    result.telecom = phoneMatches[0].trim();
    query = query.replace(result.telecom, "");
  }

  query = query.trim();

  if (query) {
    result.query = query;
  }

  return result;
}
