import { useMemo } from "react";
import { Approximate, ApproximateRange, Duration, type Encounter, HealthcareService, IStore, NoteRule, NoteRuleCriteriaType, NoteRulesClient, Reference } from "@remhealth/apollo";
import { FormContent, createNoteRulesView } from "@remhealth/core";
import { isSameDay } from "@remhealth/ui";
import { useNoteDialog } from "~/contexts";
import { GroupNoteForm, NoteForm } from "../types";
import { useNoteRulesFeed } from "./useNoteRulesFeed";

export async function loadNoteRules(store: IStore<NoteRulesClient>, encounter: Encounter, criteriaTypes: NoteRuleCriteriaType[], blockersOnly: boolean, abort: AbortSignal): Promise<NoteRule[]> {
  const noteRulesView = createNoteRulesView(store, {
    criteriaTypes,
    outcomeTypes: blockersOnly ? ["Blocker", "ChangeService"] : undefined,
    limitToServiceIds: encounter.serviceType ? [encounter.serviceType.id] : undefined,
  });

  while (noteRulesView.canLoadMore && !abort.aborted) {
    await noteRulesView.loadMore({ maxItemCount: 100, abort });
  }

  return filterNoteRules(noteRulesView.items(), encounter.duration, encounter.period, encounter.serviceType);
}

export function useNoteRules(form: FormContent<NoteForm> | FormContent<GroupNoteForm> | undefined, blockersOnly = false): NoteRule[] {
  const serviceType = form?.fields.serviceType.value;
  const duration = form?.fields.duration?.value;
  const period = form?.fields.period.value;

  const { groupNote, patientNote } = useNoteDialog();

  const enabled = !!patientNote // Has an active note
    && !groupNote // Not a group note
    && !!form
    && patientNote.id === form.values.id
    && patientNote.partOf === undefined; // Not a group patient note

  const criteriaTypes: NoteRuleCriteriaType[] = ["SessionDuration", "SpanMidnight", "SessionDurationIncrement"];

  const noteRules = useNoteRulesFeed({
    disabled: !enabled,
    criteriaTypes,
    outcomeTypes: blockersOnly ? ["Blocker", "ChangeService"] : undefined,
    limitToServiceIds: serviceType ? [serviceType.id] : undefined,
  }, true);

  return useMemo(() => {
    return filterNoteRules(noteRules, duration, period, serviceType );
  }, [noteRules, duration, period?.start, period?.end]);
}

function filterNoteRules(
  rules: NoteRule[],
  duration?: Duration,
  period?: ApproximateRange,
  serviceType?: Reference<HealthcareService>
): NoteRule[] {
  const filteredRules: NoteRule[] = [];

  for (const rule of rules) {
    if (rule.outcome.type === "ChangeService" && rule.outcome.services.some(service => service.id === serviceType?.id)) {
      continue;
    }
    if (rule.criteria.type === "SessionDuration" && duration) {
      const ruleCriteria = rule.criteria.minimum
        ? Duration.toLuxon(rule.criteria.minimum).valueOf() < Duration.toLuxon(duration).valueOf()
        : rule.criteria.maximum
          ? Duration.toLuxon(rule.criteria.maximum).valueOf() > Duration.toLuxon(duration).valueOf()
          : false;
      if (ruleCriteria) {
        filteredRules.push(rule);
      }
    } else if (rule.criteria.type === "SpanMidnight" && period?.start && period.end) {
      if (!serviceType) {
        continue; // SpansMidnight rule requires service type
      }
      const isExcludingService = rule.criteria.excludingServices.some(s => s.id === serviceType.id);
      if (isExcludingService) {
        continue;
      }
      if (!isSameDay(Approximate.toDateTime(period.start), Approximate.toDateTime(period.end))) {
        filteredRules.push(rule);
      }
    } else if (rule.criteria.type === "SessionOverlap") {
      if (rule.limitToServices.length === 0 || rule.limitToServices.some(s => s.id === serviceType?.id)) {
        filteredRules.push(rule);
      }
    } else if (rule.criteria.type === "SessionDurationIncrement" && duration) {
      const incrementMinutes = Duration.toLuxon(rule.criteria.increment).as("minutes");
      const durationMinutes = Duration.toLuxon(duration).as("minutes");
      const ruleCriteria = durationMinutes > 0 && (durationMinutes % incrementMinutes) !== 0;
      if (ruleCriteria) {
        filteredRules.push(rule);
      }
    }
  }

  return filteredRules;
}
