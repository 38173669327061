import { useReleaseCheck } from "@remhealth/host";
import { Coding, Note, NoteSectionFormat } from "@remhealth/apollo";
import { Text } from "~/text";
import { Container, NoData, SectionHeader } from "./common.styles";

export interface EvidenceBasedPracticesSectionContentProps {
  note: Note;
}

export function EvidenceBasedPracticesSectionContent(props: EvidenceBasedPracticesSectionContentProps) {
  const { note } = props;
  const isElementsReleased = useReleaseCheck("EBPElements");

  const section = note.sections.find(section => section.format === NoteSectionFormat.EvidenceBasedPractices);
  const name = section?.name;
  const showServiceElements = section?.useEvidenceBasedPracticeElements;

  return (
    <Container>
      <SectionHeader>{name}</SectionHeader>
      {rednerEvidenceBasedPracticesCodings()}
    </Container>
  );

  function rednerEvidenceBasedPracticesCodings() {
    if (note.evidenceBasedPracticesCodings.length === 0) {
      return <NoData>{Text.NoData}</NoData>;
    }

    if (isElementsReleased && showServiceElements) {
      return (
        <div>
          <div>{getCodingText(note.evidenceBasedPracticesCodings[0])}</div>
          <ul>{note.evidenceBasedPracticesCodings[0].elements.map(c => <li key={c.code}>{getCodingText(c)}</li>)}</ul>
        </div>
      );
    }

    return <ul>{note.evidenceBasedPracticesCodings.map(c => <li key={c.code}>{getCodingText(c)}</li>)}</ul>;
  }

  function getCodingText(coding: Coding) {
    const parts = [coding.code];
    if (coding.display) {
      parts.push(coding.display);
    }

    return parts.join(" - ");
  }
}
