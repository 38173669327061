import { useContext, useMemo } from "react";
import { Encounter, EncounterStatus, Note, NoteSection, NoteSectionFormat, VisitStatus } from "@remhealth/apollo";
import { getVisitStatus } from "~/utils";
import { CarePlanSessionInfo } from "~/goals/carePlanSessionInfo";
import { SpeechContext } from "~/compose/language/speechContext";
import {
  AddOnServiceSectionContent,
  ClinicalQualityCodesSectionContent,
  CtoneCustomDropdownSectionContent,
  DiagnosisSectionContent,
  EvidenceBasedPracticesSectionContent,
  GoalsObjectivesSectionContent,
  ProblemListSectionContent,
  QuestionnairePreviewOutput,
  SessionTimeSectionContent,
  TextSectionContent
} from "./sections";

export interface NoteSectionContentProps {
  encounter: Encounter;
  note: Note;
  section: NoteSection;
  highlights?: string[];
  isPatientView?: boolean;
}

export function NoteSectionContent(props: NoteSectionContentProps) {
  const { encounter, note, section, highlights, isPatientView } = props;

  const rootSpeechContext = useContext(SpeechContext);
  const speechContext = useMemo<SpeechContext>(() => ({
    ...rootSpeechContext,
    sectionName: section.name,
  }), [rootSpeechContext, section.name]);

  if (section.format !== NoteSectionFormat.Form && !section.includeInPatientView && isPatientView) {
    return <></>;
  }

  return (
    <SpeechContext.Provider value={speechContext}>
      {renderSection()}
    </SpeechContext.Provider>
  );

  function renderSection() {
    switch (section.format) {
      case NoteSectionFormat.Form: {
        const answers = note.sections.find(s => s.format === "Form" && s.name === section.name)?.formAnswers ?? [];
        return (
          <QuestionnairePreviewOutput
            answers={answers}
            elements={section.form?.resource?.elements ?? []}
            includeSectionHeader={section.includeSectionHeader}
            isPatientView={isPatientView}
            sectionName={section.name}
          />
        );
      }
      case NoteSectionFormat.Diagnosis:
        return (
          <DiagnosisSectionContent
            key={section.name}
            diagnoses={encounter.diagnoses}
            name={section.name}
          />
        );
      case NoteSectionFormat.GoalsObjectives:
        return (
          <GoalsObjectivesSectionContent
            key={section.name}
            activities={encounter.carePlanActivities}
            name={section.name}
          />
        );
      case NoteSectionFormat.SessionTime:
        return renderSessionTime(section);
      case NoteSectionFormat.Text:
        return renderTextViewSection(section, highlights);
      case NoteSectionFormat.CtoneCustomDropdown:
        return renderCtoneCustomDropdown(note);
      case NoteSectionFormat.ClinicalQualityIndicator:
        return renderClinicalQualityCodesDropdown(note);
      case NoteSectionFormat.EvidenceBasedPractices:
        return <EvidenceBasedPracticesSectionContent key={section.name} note={note} />;
      case NoteSectionFormat.GoalTracking:
        return <CarePlanSessionInfo carePlanActivities={note.derivedFrom?.resource?.carePlanActivities ?? []} label={section.name} />;
      case NoteSectionFormat.Problems:
        return (
          <ProblemListSectionContent
            key={section.name}
            name={section.name}
            problems={encounter.problems}
          />
        );
      case NoteSectionFormat.AddOn:
        return <AddOnServiceSectionContent additionalServices={note.additionalServices} name={section.name} />;
      default:
        return null;
    }
  }

  function renderSessionTime(section: NoteSection) {
    const isShowStatus = getVisitStatus(note.encounter.resource?.status) === VisitStatus.Show;
    if (note.partOf && (!isShowStatus || !note.overrideSessionInformation)) {
      return null;
    }

    return renderNoteSessionTime(note, section, encounter);
  }

  function renderCtoneCustomDropdown(note: Note) {
    return (
      <CtoneCustomDropdownSectionContent key="CtoneCustomDropdown" note={note} />
    );
  }

  function renderClinicalQualityCodesDropdown(note: Note) {
    return (
      <ClinicalQualityCodesSectionContent key="ClinicalQualityCodes" note={note} />
    );
  }
}

function renderTextViewSection(section: NoteSection, highlights?: string[]) {
  return (
    <TextSectionContent
      key={section.name}
      highlights={highlights}
      name={section.name}
      text={section.text}
    />
  );
}

function renderNoteSessionTime(note: Note, section: NoteSection, encounter?: Encounter) {
  return (
    <SessionTimeSectionContent
      key={section.name}
      encounter={encounter}
      name={section.name}
      note={note}
      showInvalid={encounter?.status !== EncounterStatus.Cancelled}
    />
  );
}
